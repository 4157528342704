<style>
.daterangepicker .calendars-container {
  margin-left: 20% !important;
  margin-top: -43% !important;
}
.brand {
  height: 47.5px !important;
  width: 100% !important;
  background: #eef0f8 !important;
  top: -48.5px !important;
}
</style>
<style lang="scss" scoped>
.graph {
  margin-top: 2%;
  .graph-left {
    .graph-left-text {
      position: absolute;
      top: 0%;
      left: 7%;
    }
  }
  img {
    width: 39%;
  }
  .graph-mid {
    margin-left: 14%;
  }
  .card {
    width: 100%;
    margin-top: 1%;
  }
}
.text-color-blue {
  color: #007bff;
}
.vue-daterange-picker {
  margin-top: 4%;
  margin-left: 26%;
}

.btn-active {
  color: #ffffff;
  background-color: #3699ff;
  border-color: #3699ff;
}
.card-header {
  background-color: #3699ff;
  color: #ffff !important;
}
.dash {
  .card {
    .card-header {
      padding: 1rem 2.25rem;
    }
  }
}
.form-check-flex {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.appointment-list {
  width: 100%;
  height: 100%;
  .opacityadd {
    opacity: 1 !important;
  }
  input {
    display: none;
    &:checked + label {
      background: #eee;
    }
  }
  label {
    display: inline-block;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    width: 50%;
    text-align: center;
    background-color: #007bff !important;
    color: #ffff;
    position: relative;
    transition: 0.25s background ease;
    cursor: pointer;
  }
  .line {
    position: absolute;
    height: 4px;
    background: #ffc107;
    width: 50%;
    top: 5%;
    left: 0;
    transition: 0.25s ease;
  }
  .scrollable::-webkit-scrollbar {
    width: 1em;
  }

  .scrollable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .scrollable::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0px solid slategrey;
  }
  .content-container {
    background: #ffff;
    // position: absolute;
    // height: 60%;
    font-size: 12px;
    // overflow-y: scroll;
    // width: 73%;
    margin-left: 0%;
    .scrollable {
      overflow-y: scroll;
      height: 500px;
    }
    .scroll1 {
      width: 43%;
    }
    .scroll2 {
      width: 43%;
    }

    .content {
      padding: 10px;
      h3 {
        font-weight: 200;
        margin: 10px 0;
      }
      p {
        margin: 10px 0;
      }
      p,
      i {
        font-size: 13px;
      }
    }
  }
}
</style>
<style scoped>
.spinner-3 {
  margin-left: 35%;
}
</style>
<template>
  <!-- <h1>Dashboard</h1> -->
  <div>Dashboard</div>
  
</template>

<script>
// import DateRangePicker from "vue2-daterange-picker";
// import BarChart from "@/includes/graph/Chart";
// import LineChart from "@/includes/graph/Chart";

// import DashboardCard from "@/components/Dashboard/DashboardCard";
// import vDataLoader from "@/components/frontend/spinner.vue";
// import AppointmentList from "@/pages/backend/appointment/lists/index";

// import {
//   GET_DASHBOARD_CARD_DATA,
//   FETCH_DATA_FOR_GRAPH
// } from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {

  data() {
   
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: ""
        }
      ],
    };
  },
  
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
   
  },
  // destroyed() {
  //   var chart = new VueApexCharts(el, options);
  //   chart.destroy();
  // }
};
</script>
